<template>
  <div class="container">
    <button
        @click="$emit('cat-all')"
        :disabled="chosenCat == null"
        :class="{
          'cat-all': true,
          'option': null !== chosenCat,
          'selected': null === chosenCat,
        }"
        
      >
        All
      </button>

    <span v-if="filters">
      <button
        v-for="filter in filters" :key="filter.term_id"
        @click="$emit('cat-change', filter.term_id)"
        :class="{
          'cat': true,
          'option': filter.term_id !== chosenCat,
          'selected': filter.term_id === chosenCat,
        }"
        :disabled="filter.term_id === chosenCat"
      >
        {{filter.name}}
      </button>
      <!-- Multi line debug -->
      <!-- <button
        v-for="filter in filters" :key="filter.term_id"
        @click="$emit('cat-change', filter.term_id)"
        :class="{
          'cat': true,
          'option': filter.term_id !== chosenCat,
          'selected': filter.term_id === chosenCat,
        }"
      >
        {{filter.name}}
      </button> -->
    </span>
  </div>
</template>

<script>
export default {
  props: {
    filters: Array,
    chosenCat: Number
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/container';
@import '../styles/grid';
@import '../styles/text';
@import "../styles/buttons";

  .container {
    @extend %container;
    @extend %mobile-container;

    /* margin-top: 35px; // 50 - 15 */
    /* margin-bottom: 55px; */

    @media (max-width: 800px) {
      @include m-margin(0.5);
      /* margin-top: 25px; */
      /* margin-bottom: 25px; */
    }
  }

  .cat-all {
    /* @extend %btn-solid-red ; */
    margin-top: 15px;
    cursor: pointer;

    @media (max-width: 800px) { 
      display: block; // force all on to new line
      margin-top: 0px;
    }
  }

  .cat {
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;

    @media (max-width: 800px) {
      display: block; // force all on to new line
      margin-left: 0px;
      margin-top: 15px;
    }
  }

  .cat.option, .cat-all.option {
    @extend %btn-outline-filter;
    @media (max-width: 800px) { 
      display: block;
    }
  }

  .cat.selected, .cat-all.selected {
    @extend %btn-solid-red;

    &:hover, &:focus {
      cursor: default;
      background-color: $red;
      border-color: $red;
      color: white;
    }

    @media (max-width: 800px) { 
      display: block;
    }
  }

  
</style>
