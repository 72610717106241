var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('button',{class:{
        'cat-all': true,
        'option': null !== _vm.chosenCat,
        'selected': null === _vm.chosenCat,
      },attrs:{"disabled":_vm.chosenCat == null},on:{"click":function($event){return _vm.$emit('cat-all')}}},[_vm._v("\n      All\n    ")]),(_vm.filters)?_c('span',_vm._l((_vm.filters),function(filter){return _c('button',{key:filter.term_id,class:{
        'cat': true,
        'option': filter.term_id !== _vm.chosenCat,
        'selected': filter.term_id === _vm.chosenCat,
      },attrs:{"disabled":filter.term_id === _vm.chosenCat},on:{"click":function($event){return _vm.$emit('cat-change', filter.term_id)}}},[_vm._v("\n      "+_vm._s(filter.name)+"\n    ")])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }