<template>
  <div>
    <!-- Loading. -->
    <transition name="loading">
      <div v-if="!page || !works" class="loading">
        <Loader />
      </div>
    </transition>

    <!-- Content. -->
    <div 
      v-if="page" 
      class="page-box content work" 
      ref="work"
    >
      
      <!-- The video Modal -->
      <VideoModal
        :active="videoModalActive"
        :embed_url="video.embed_url"
        :height="Number(video.height)"
        :width="Number(video.width)"
        @close="videoModalActive = false"
      />

      <!-- The Photography modal -->
      <PhotographyModal
        :active="imageModalActive"
        :images="images"
        @close="imageModalActive = false"
      />
      
      <!-- Hero text -->
      <StdTextHero 
        :title="page.title.rendered" 
        :content="page.acf.short_text"
      />

      <!-- List of catagories to filter by -->
      <WorkFilters
        class="WorkFilters"
        :filters="workListCategories"
        :chosenCat="chosenCat"
        @cat-change="onTheCatChange"
        @cat-all="onTheCatAll"
      />

      <!-- List of times -->
      <WorkList
        class="WorkList"
        :works="works" 
        :openModal="openModal"
       />

      <!-- Footer -->
      <Contact2021 
        class="Contact2021"
        :contact="page.acf.contact" 
        v-if="page.acf.contact && page.acf.contact.contact_blurb && page.acf.contact.subtitle && page.acf.contact.title"
      />
      <SubscribeForm2021
        class="SubscribeForm2021"
      />
      <CopyrightFooter
        class="CopyrightFooter"
        v-if="copyright && copyright !== ''"
        :copyright="copyright"
      />

    </div>
  </div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import Loader from '../components/Loader'
import { TweenLite } from 'gsap' // note due to an import bug tween max is not avalible
import { throttle } from 'lodash'
import WorkList from '../components/WorkList'
// import WorkHero from '../components/WorkHero'
import VideoModal from '../components/VideoModal'
import SubscribeForm2021 from '../components/SubscribeForm2021'
import Contact2021 from '../components/Contact2021'
import WorkFilters from '../components/WorkFilters'
import CopyrightFooter from '../components/CopyrightFooter'
import StdTextHero from '../components/StdTextHero'
import PhotographyModal from '../components/PhotographyModal'

export default {
  mixins: [FadeIn, MatchedRoute, Wordpress],
  data () {
    return {
      show: true,
      page: null,
      primaryNavActive: false,
      bgOffset: 1,
      eventListenerScroll: null,
      eventListenerResize: null,
      scene: null,
      works: [],
      chosenCat: null,
      videoModalActive: false,
      imageModalActive: false,
      images: [],
      video: {
        embed_url: '',
        width: 640,
        height: 360
      }
    }
  },
  computed: {
    workListCategories () {
      return (this?.page?.acf?.work_list || []).map((wl) => wl?.category)
    },

    workLists () {
      let newList = {};
      (this?.page?.acf?.work_list || []).forEach(({ category, work }) => {
        const term_id = category?.term_id
        if (term_id) {
          newList[term_id] = work || []
        }
      })

      return newList
    },
    testImages () {
      return []
    },

    copyright () {
      return this?.page?.acf?.copyright_footer?.copyright || ''
    },
    showContact () {
      return (
        this.page.acf.contact.contact_blurb && this.page.acf.contact.subtitle && this.page.acf.contact.title
      )
    }
  },
  methods: {
    async onCatChange (cat) {
      this.chosenCat = cat
      // console.log(this.chosenCat)
      this.works = await this.$wp.getWorkByCat(cat)
    },

    onTheCatChange (term_id) {
      this.chosenCat = term_id
      this.works = (this.workLists || {})[term_id] || []
    },

    onTheCatAll () {
      this.chosenCat = null
      this.works = this?.page?.acf.work_list_all || []
    },

    async onCatAll () {
      this.chosenCat = null
      this.works = await this.$wp.getWorkByAll()
    },

    async fetchPage () {
      let page = null
      if (this.$route.query.preview) {
        page = await this.$wp.getPagePreviewByPath(
          this.$matchedRoute.pathResolved
        )
      } else {
        page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }

      
      this.page = page
      // console.log(this.page)
      this.works = this?.page?.acf.work_list_all || []
      // Old AJAX get by id
      // this.works = await this.$wp.getWorkByAll()

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(
                this.$refs.work,
                30,
                {
                  backgroundPositionY: '0%'
                },
                {
                  backgroundPositionY: `100%`
                }
              )
            ) // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration('100%')
        })
      }
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    },
    openModal (work) {
      if (this.$wp.workTypes.modal.vimeo === work?.acf?.modal_type) {
        this.video = work?.acf?.vimeo || {}
        this.videoModalActive = true
      } else if (this.$wp.workTypes.modal.photography === work?.acf?.modal_type) {
        this.images = work?.acf?.photography || []
        this.imageModalActive = true
      } else {
        console.warn(`@Work->openModal unsuported modal type of ${work?.acf?.modal_type}`)
      }
    }
  },
  created () {
    // this.fetchWork()
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, {
        passive: true
      })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, {
        passive: true
      })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchPage())
  },
  components: {
    Loader,
    WorkList,
    // WorkHero,
    VideoModal,
    SubscribeForm2021,
    Contact2021,
    WorkFilters,
    CopyrightFooter,
    StdTextHero,
    PhotographyModal
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/container';
  @import '../styles/buttons';
  @import '../styles/grid';
  @import "../styles/text";
  @import "../styles/variables";

  .work {
    background-image: url('../assets/work/bg.svg');
    background-position: top left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: 266.3px 872px;
    @media (max-width: 800px) {
      background-image: none;
    }
  }

  .page-box {
    padding-top: 175px;
    padding-bottom: 110px;

    @media (max-width: 800px) {
      padding-top: 110px;
    }
  }

  .loading {
    @include loading;
  }
  @include loading-page-fade;


  .WorkFilters {
    margin-top: 50px;
    @media (max-width: 800px) {
      margin-top: 25px;
    }
  }
  
  .WorkList {
    /* Remove the last item spacing */
    /* Same on M and D */
    margin-bottom: -56px;
    margin-top: 50px;
    @media (max-width: 800px) {
      margin-top: 25px;
    }
  }

  .Contact2021 {
    margin-top: 130px;
    @media (max-width: 800px) {
      margin-top: 110px;
    }
  }

  .SubscribeForm2021 {
    margin-top: 60px;
  }
  
  .CopyrightFooter {
    margin-top: calc(60px - 10px);
  }


</style>
